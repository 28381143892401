/* UserInfoModal.css */
.user-info-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.5s ease-in-out;
}

.user-info-modal {
    background-color: #fff;
    padding: 40px;
    max-width: 400px;
    text-align: center;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

@media (max-width: 350px) {
  /* Adjust styles for smaller screens */
  .user-info-modal {
    padding: 20px;
    max-width: 200px;
  }
}

.user-info-modal .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}

.user-info-modal .modal-content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
}

.user-info-modal h2.premium {
    font-size: 24px;
    color: green;
}

.user-info-modal h2.not-premium {
    font-size: 24px;
    color: red;
}

.user-info-modal p {
    font-size: 18px;
}

.user-info-modal button {
    padding: 10px 20px;
    font-size: 16px;
    margin-top: 20px;
}

.user-info-modal .premium-button {
    background-color: green;
    color: white;
    border: none;
    cursor: pointer;
}
