.user-info-box {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    margin-top: 20px;
    margin-right: 10px;
    cursor: pointer;
    padding: 8px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    transition: background-color 0.3s ease-in-out;

    .premium-indicator {
        color: #4caf50;
        /* Green color for premium indicator */
        margin-right: 10px;
    }

    .user-info {
        display: flex;
        align-items: center;
    }

    .user-account {
        display: flex;
        align-items: center;
    }

    .user-glyph {
        font-size: 1.5em;
        margin-right: 5px;
    }

    .user-email {
        font-size: 1m;
    }

    .user-info-box:hover .info-box {
        max-height: 100px;
        /* Adjust max height as needed */
    }

    .user-info-box:hover {
        background-color: #f0f0f0;
    }

    .info-box {
        position: inherit;
        z-index: 2;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        margin-top: 5px;
        margin-left: 5px;
        overflow: hidden;
        /* max-height: 0; */
        right: 0;
        top: 100%;
        /* Position it below the user-info-box */
        width: 150px;
        /* Adjust the width as needed */
        transition: visibility 0.3s ease-in-out  max-height 0.3s ease-in-out;
    }

    .info-option {
        display: block;
        width: 100%;
        padding: 10px;
        margin-left: 0;
        text-align: left;
        background-color: transparent;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        color: black;
    }

    .info-option:hover {
        background-color: #f0f0f0;
    }
}

/* @media (max-width: 1100px) {
    .premium-login-box {
        display: flex;
        flex-wrap: wrap;
        display: flex;
        flex-direction: column;
        order: 3;
        margin-top: 5px;
        margin-right: 0;

        .user-info-box {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            margin-top: 0;

            .user-email {
                text-wrap: wrap;
            }

            .info-box {
                top: auto;
                max-height: none;
            }

            .info-option {
                width: 100%;
                /* Make sure the options take full width */ 
/*} } } } */