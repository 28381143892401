.header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    width: 100%;
}

.header-container {
    display: flex;
    align-items: center;
  }


  .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    h1 {
        position: inherit;
        z-index: 3;
        font-size: 2em;
        margin: 0;
    }

    h3 {
        margin: 0;
        font-size: 1.5em;
    }
}


.main-title {
    font-size: 2.5em;
    position: inherit;
    z-index: 3;
    /* Increase title font size */
}

@media (max-width: 1100px) {
    .header-container {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
}