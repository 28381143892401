/* Add these styles to your CSS file */

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    
    button {
      margin-left: 10px;
      padding: 10px 15px;
      cursor: pointer;
      border: none;
      border-radius: 5px;
      background-color: #3498db;
      color: #fff;
    }
  }
  
  .popup.visible {
    visibility: visible;
    z-index: 9999;
  }
  
  .popup-content {
    max-width: 300px;
    background: #fff; /* White background for the pop-up content */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  

  