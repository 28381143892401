/* OneStopEditorHome.css */
.container {
    display: block;
    position: relative;
    z-index: 2;
    padding: 0px;
    width: 100%;
    min-height: 75vh;
    clear: both;
}

.one-stop-editor-home {
    display: flex;
    position: relative;
    flex-direction: column;
    font-family: 'Arial', sans-serif;
    background-color: #f8f8f8;
    color: #333;
    padding: 10px 0 42px 0;
    position: relative;
    padding-top: 0;

    justify-content: center;
    align-items: center;
    animation: fadeIn 1s ease-in-out;


    .header {
        position: relative;
        z-index: 3;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 0px;

        .header-container {
            display: flex;
            align-items: center;
            flex-direction: column;

            .back-button {
                .button {
                    border: 1px solid #ddd;
                    border-radius: 8px;
                }
            }
        }
    }



    .title {
        order: 2;

        h1 {
            font-size: 2em;
            margin: 0;
        }
    }

    .main-title {
        font-size: 2.5em;
        /* Increase title font size */
    }

    .center-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .top-center-container {
        display: flex;
        /* align-items: center; */
        justify-content: start;
    }

    .services-section {
        display: flex;
        width: 100%;
        position: inherit;
        margin-top: 20px;
        overflow: hidden;
        text-align: center;
        z-index: 2;

        .scroll-container {
            display: flex;
            text-align: center;
            align-items: center;
        }

        .services-list {
            max-width: 90%;
            display: flex;
            overflow-x: hidden;
            scroll-behavior: smooth;
            transition: transform 0.3s ease-in-out;
        }

        .service-box {
            flex: 0 0 auto;
            flex-direction: column;
            /* min-width: 300px; */
            /* max-width: 300px; */
            padding: 20px;
            margin: 5px;
            height: 150px;
            max-width: 400px;
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            text-align: center;
            scroll-snap-align: start;
            transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
            cursor: pointer;
        }

        .service-box:hover {
            box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
            background-color: #eee;
        }

        .service-box:active {
            transform: scale(0.95);
        }

        .service-box h2 {
            font-size: 1.5em;
            margin-top: 5px;
            margin-bottom: 5px;
            color: black;
        }

        .service-box p {
            font-size: 1.1em;
            margin-bottom: 15px;
            color: black;
        }

        .scroll-icon {
            background-color: #3498db;
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            width: 40px;
            margin: 0 10px;
            color: #fff;
            font-size: 20px;
            transition: background-color 0.3s ease-in-out;
        }

        .scroll-icon:hover {
            background-color: #2980b9;
        }

    }

    input {
        z-index: 2;
        border: none;
        outline: none;
        background: transparent;
        flex: 1;
    }

    .search-filters-section {
        position: inherit;
        z-index: 2;
        display: flex;
        flex-direction: column;
        min-width: 200px;
        width: fit-content;
        background-color: #fff;
        margin: 0 auto;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .search-box {
        display: flex;
        align-items: center;
        background-color: #efefef;
        border-radius: 8px;
        padding: 5px;
        i {
            margin-right: 10px;
        }
    }

    .filters-box {
        position: inherit;
        z-index: 2;
        display: flex;
        align-items: center;

        .filter-option {
            border: transparent;
            margin-right: 10px;
            cursor: pointer;
        }
    }

    .your-section {
        position: inherit;
        z-index: 2;
        display: flex;
        /* align-items: center; */
        justify-content: space-between;

        .your-list {
            /* z-index: 1; */
            max-width: 100%;
            overflow-y: auto;
            /* max-height: 280px; */
            display: flex;
            flex-direction: row;
            overflow-y: hidden;
            scroll-behavior: smooth;
            transition: transform 0.3s ease-in-out;
        }

        .list-view {
            max-height: 500px;
            min-height: 100px;
            overflow-y: auto;
            width: 550px;
            /* Adjust the width as needed */
            min-width: 300px;
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 8px;
            padding: 20px;
            margin-right: 10px;
            margin-bottom: 20px;
        }

        h2 {
            font-size: 1.5em;
            color: black;
        }

        .document-item {
            position: relative;
            background-color: #f8f8f8;
            border: 1px solid #ccc;
            border-radius: 8px;
            padding: 15px;
            width: 100%;
            /* min-width: 320px; */
            margin-top: 0px;
            margin-bottom: 10px;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out;

            &:hover {
                background-color: #e0e0e0;
            }

            .document-info {
                display: flex;
                align-items: center;
                justify-content: space-between;

                h3 {
                    font-size: 1.2em;
                    margin: 0;
                    float: left;
                    color: black;
                }

                p {
                    font-size: 0.9em;
                    margin-right: 5px;
                    float: right;
                    color: black;
                }
            }
        }

        .title-button-pair {
            position: relative;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
        }
    }
}

@media (max-width: 1100px) {
    .one-stop-editor-home {

        .center-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            width: 100%;
        }

        .filters-box {
            margin-top: 5px;
        }

        .services-section {
            display: flex;
            width: 100%;
            justify-content: center;

            .services-list {
                margin: 0px 10px 0 10px;
                z-index: 1;
                max-width: 100%;
                max-height: 280px;
                display: flex;
                flex-direction: column;
                overflow-y: scroll;
                scroll-behavior: smooth;
                transition: transform 0.3s ease-in-out;
            }

            .service-box {
                width: 99%;
                max-width: none;
                min-height: fit-content;
                margin: 5px 0;
                align-items: center;
            }

            .scroll-icon {
                visibility: hidden;
                display: none;
                z-index: 0;
            }
        }



        .your-section {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;

            .your-list {
                margin: 0 10px 0 10px;
                display: flex;
                flex-direction: column;
                z-index: 1;
                justify-content: center;
                max-width: 100%;
                width: 100%;
                overflow-y: hidden;
                scroll-behavior: smooth;
                transition: transform 0.3s ease-in-out;

                /* Add these styles for better mobile responsiveness */
                .list-view {
                    max-height: 250px;
                    max-width: 100%;
                    min-height: 130px;
                    min-width: fit-content;
                    width: auto;

                    overflow-y: auto;
                    display: flex;
                    flex-direction: column;
                    transition: transform 0.3s ease-in-out;

                    background-color: #fff;
                    border: 1px solid #ccc;
                    border-radius: 8px;
                    padding: 10px;
                    margin-right: 0px;
                    margin-bottom: 20px;
                }

                h2 {
                    font-size: 1.5em;
                    color: black;
                }

                .title-button-pair {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                .document-item {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    /* width: 100%; */

                    max-width: 100%;
                    /* Adjust to your preference */
                    /* width: 2; */
                    margin: 5px 0;
                    background-color: #f8f8f8;
                    border: 1px solid #ccc;
                    border-radius: 8px;
                    padding: 15px;
                    margin-bottom: 10px;
                    cursor: pointer;
                    transition: background-color 0.3s ease-in-out;

                    &:hover {
                        background-color: #e0e0e0;
                    }

                    .document-info {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        min-width: fit-content;
                        max-width: none;

                        h3 {
                            font-size: 1.2em;
                            margin: 0;
                            float: left;
                            color: black;
                        }

                        p {
                            font-size: 0.9em;
                            position: relative;
                            right: 0;
                            margin-right: 5px;
                            float: right;
                            color: black;
                        }
                    }
                }

            }
        }

    }

}