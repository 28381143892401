.summary-generator{
    font-family: 'Arial', sans-serif;
    background-color: #f8f8f8;
    overflow: hidden;
    color: #333;
    position: relative;
    /* Add relative positioning to allow absolute positioning */
  
    display: flex;
    flex-direction: column;
    /* Ensure it's a column layout */
    align-items: center;
    /* Center items horizontally */
    animation: fadeIn 1s ease-in-out;

    .downloadContainer{
        position: absolute;
        right: 0;
        bottom: 30px;
        margin-right: 7%;
    }
}

