/* QuestionGenerator.css */

.question-generator {
  font-family: 'Arial', sans-serif;
  background-color: #f8f8f8;
  overflow: hidden;
  color: #333;
  position: relative;
  /* Add relative positioning to allow absolute positioning */

  display: flex;
  flex-direction: column;
  /* Ensure it's a column layout */
  align-items: center;
  /* Center items horizontally */
  animation: fadeIn 1s ease-in-out;

  .tabs {
    display: flex;
    width: max-content;
    margin-bottom: 15px;
  }
 
  .questions-tab,
  .solutions-tab {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
  }
  
  .question-box,
  .solution-box {
    background-color: #f8f8f8;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }
  
  .question-box h3,
  .solution-box h3 {
    margin-bottom: 10px;
    font-size: 1.2em;
  }
  
  .question-box p,
  .solution-box p {
    color: #555;
  }
  
}





/* .input-upload-section {
  grid-column: 1;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
} */

/* .question-output-section {
  grid-column: 2;
  flex: 1;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 10px;
} */



/* Tabs Styles */

