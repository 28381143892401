/* QuestionBoxes.css */

.question-box {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  background-color: #fff;
}

.question {
  font-weight: bold;
  margin-bottom: 10px;
}

.options {
  display: flex;
  flex-direction: column;
}

.option {
  margin-bottom: 5px;
}

.question-box.selected {
  background-color: #3498db;
}

.option.highlighted {
  /* Add your styles for highlighted options */
  font-weight: bold;
  /* Example: bold text */
  display: inline-block;
  padding: .25em 0;
  background: green;
  color: #ffffff;
}

/* Additional styling specific to each question type */

.multiple-choice {
  /* Add specific styling for multiple-choice questions */
}

.true-false {
  /* Add specific styling for true/false questions */
}

.short-answer {
  /* Add specific styling for short-answer questions */
}