/* src/components/OneStopSignUpLogin.css */
.one-stop-sign-up-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    animation: fadeIn 1s ease-in-out;
    i{
        margin-right: 5px;
    }
}

/* Add a wrapper container for the forms and external providers */
.forms-wrapper {
    display: flex;
    align-items: flex-start;
    /* Align forms and providers to the start of the flex container */
}

/* Flex Container for the first two containers to be side by side */
.side-by-side {
    display: flex;
}

.form-container,
.provider-container {
    position: inherit;
    z-index: 3;
    margin-top: 20%;
    flex: 1;
    /* Take up remaining space */
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    /* Add opacity transition */
    height: 350px;
    width: 300px;
    /* Set a fixed width for consistency */
    margin-right: 20px;
    /* Add margin between the standard and external provider boxes */
}

.form-container.hidden,
.provider-container.hidden {
    opacity: 0;
    pointer-events: none;
    /* Disable pointer events while hidden */
}

.form-container h2,
.provider-container h2 {
    margin-bottom: 20px;
}

.form-container label,
.provider-container label {
    display: block;
    margin: 10px 0;
    text-align: left;
    /* Align labels to the left */
}

.form-container input,
.provider-container input {
    width: calc(100% - 20px);
    /* Adjust input width to fit within the container */
    padding: 8px;
    margin-bottom: 10px;
    box-sizing: border-box;
    /* Include padding and border in the input width */
}

.form-container button,
.provider-container button {
    width: 100%;
    padding: 10px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;

    &:hover {
        background-color: #2980b9;
    }
}

.form-container p,
.provider-container p {
    margin-top: 20px;
    color: #3498db;
    cursor: pointer;
    text-decoration: underline;
}

.provider-container {
    margin-left: 20px;
    /* Add margin to separate the standard and external provider boxes */
}

.provider-buttons {
    display: flex;
    flex-direction: column;
}

.provider-buttons button {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.provider-buttons button img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.already-have-account {
    cursor: pointer;
    margin-top: 20px;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    /* Add margin above the "Already have an account" button */
}
.already-have-account.hidden {
    opacity: 0;
    pointer-events: none;
    /* Disable pointer events while hidden */
}

.already-have-account p {
    color: #3498db;

    &:hover {
        color: #2980b9;
        text-decoration: underline;
    }
}