/* src/components/OneStopPage.css */


.top-section-image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;

    .top-section-image {
        opacity: 0.3;
        position: relative;
        
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        z-index: 0;
    }
}
.top-section {
    position: relative;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column; /* Stack children vertically */
    justify-content: center;
    padding: 80px;
    z-index: 1;
}
.centered-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Adjust as needed */
  }
  

.one-stop-page {
    font-family: 'Arial', sans-serif;
    background-color: #f8f8f8;
    color: #333;
    animation: fadeIn 1s ease-in-out;
    background-color: rgba(186, 182, 181, 0.9);


    .get-started-button {
        position: inherit;
        display: inline;
        text-align: center;
        background-color: #3498db;
        height: fit-content;
        color: #fff;
        font-size: 1.2em;
        padding: 10px;
        margin: 0;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;

        &:hover {
            background-color: #2980b9;
        }
    }

    .capability-section {
        z-index: 2;
        position: relative;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
    }

    .capability {
        position: inherit;
        z-index: 3;
        opacity: 0;
        width: 40%;
        padding: 20px;
        margin: 15px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        text-align: center;
        cursor: pointer;
        transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;

        &:hover {
            transform: scale(1.05);
            background-color: #2980b9;
        }

        h2 {
            font-size: 2em;
            margin-bottom: 10px;
        }

        p {
            font-size: 1.1em;
            margin-bottom: 15px;
        }

        img {
            width: 100%;
            border-radius: 8px;
        }
    }

    .feature-section {
        z-index: 2;
        position: relative;
        width: 100%;
        /* padding-top: 10px; */
        padding-bottom: 20px;
        margin: 0 auto;
        /* Center the feature section horizontally */
        /* background: linear-gradient(180deg, rgba(186, 182, 181, .8), rgba(186, 182, 181, 1)); */
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    }

    .feature.in-viewport {
        opacity: 1;
        /* transform: translateY(0); */
    }

    .capability.in-viewport {
        opacity: 1;
    }

    .feature {
        position: inherit;
        z-index: 2;
        width: 50%;
        opacity: 0;
        /* position: relative; */
        /* 50% width for responsiveness */
        margin: 10px auto;
        /* Center the feature boxes horizontally and provide spacing */
        padding: 20px;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        text-align: center;
        transition: transform 0.1s ease-in-out;

        h2 {
            font-size: 2em;
            margin-bottom: 10px;
        }

        p {
            font-size: 1.1em;
            margin-bottom: 15px;
        }

        img {
            width: 100%;
            border-radius: 8px;
        }
    }

    .top-section h1 {
        position: relative;
        z-index: 2;
        font-size: 4em;
        margin-bottom: 10px;
    }

    .top-section h2 {
        position: relative;
        z-index: 2;

        font-size: 3em;
        margin-bottom: 10px;
    }

    .top-section p {
        position: relative;
        font-size: 1.2em;
    }
}

@media (max-width: 500px) {
    .one-stop-page {
        .top-section h1 {
            position: inherit;
            font-size:3em;
            margin-bottom: 10px;
        }
        .top-section h2 {
            position: inherit;
            font-size: 2.3em;
            margin-bottom: 10px;
        }

        .top-section p {
            position: inherit;
            font-size: 1em;
        }

        .feature,
        .capability {
            width: 80%;

            h2 {
                font-size: 1.5em;
            }

            p {
                font-size: 1em;
                margin-bottom: 15px;
            }

        }

    }

}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}