.download-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    .download-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 40px;
        height: 40px;
        background-color: #4caf50;
        /* Change the background color as needed */
        border-radius: 50%;
        color: #fff;
        /* Change the text color as needed */
        font-size: 20px;
        text-decoration: none;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        transition: background-color 0.3s ease;
        cursor: pointer;
    }

    .download-button:hover {
        background-color: #2980b9;
        /* Change the background color on hover */
    }
}


.vertical-layout {
    width: 90%;
    overflow: hidden;
    overflow-x: hidden;
    position: relative;
    padding-bottom: 5%;
    min-height: 70vh;

}

.title-tooltip{
    display: flex;
    align-items: center;
}

.back-button {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 20px;
    margin-left: 10px;
    padding: 0px;
    z-index: 2;


    i {
        margin: 0;
    }

    button {
        display: flex;
        height: fit-content;
        align-items: center;
        justify-content: center;
        padding: 5px 15px 5px 15px;
        border: 1px solid #ddd;
        border-radius: 8px;
    }
}

.add-sample-button {
    flex: 0;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 50%;
    padding: 10px;
    height: 30px;
    width: 30px;
    float: right;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease-in-out;

    &:hover {
        background-color: #45a049;
    }

    span {
        font-size: 1.3em;
    }
}

.burger-button {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 20px;
    margin-left: 10px;
    padding: 0px;
    z-index: 2;
    height: 35px;
    transition: transform 0.5s ease;
    /* Add a smooth transition effect */


    img {
        height: 24px;
    }

    button {
        display: flex;
        height: fit-content;
        align-items: center;
        justify-content: center;
        padding: 5px 15px 5px 15px;
        /* border: 1px solid #ddd;
        border-radius: 8px; */
        background-color: transparent;
    }

    &:hover {
        transform: rotate(180deg);
    }
}

.save-button {
    display: flex;
    cursor: pointer;
    background-color: #3498db;
    color: #fff;
    border-radius: 5px;
    height: 100%;
    border-radius: 8px;
    width: fit-content;
    align-items: center;
    align-content: center;

    i {
        margin: 0;
    }

    button {
        margin-right: 0;
        padding: 5px 15px 5px 15px;
        border: 1px solid #ddd;
        border-radius: 8px;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 400px) {
    .vertical-layout {
        width: 100%;
        overflow: scroll;
        overflow-x: hidden;
    }
}

.glyphicon {
    bottom: 1px;
    position: relative;
    display: inline-block;
    font-family: "Glyphicons Halflings";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
  
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: rotate(135deg);
  }
  
  /* Individual styles for each glyphicon */
  .glyphicon-pencil:before {
    content: "\270f";
  }

button {
    flex: 1;
    padding: 10px;
    margin-right: 10px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    transition: background-color 0.3s ease-in-out;

    &:hover {
        background-color: #2980b9;
    }
     &:disabled{
        background-color: #2980b9;
     }
}

button.active {
    background-color: #2980b9;
}

.output-container {
    flex: 1;
    position: inherit;
    z-index: 2;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 10px;
}

.generation-customization {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
}

.spinner-1 {
    grid-column: 1;
    margin-bottom: 15px;
}

.spinner-2 {
    grid-column: 2;
    margin-bottom: 15px;
}

.options-slider {
    grid-column: 2;
}

/* Dropdown Styles */
.options-dropdown select {
    grid-column: 1;
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 5px;
    background-color: #fff;
    cursor: pointer;
    outline: none;
}

/* Style the dropdown options */
.options-dropdown select option {
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.3s ease-in-out;
}

.options-dropdown select option:hover {
    background-color: #f0f0f0;
}

.options-dropdown select option:checked {
    background-color: #3498db;
    color: #fff;
}

/* Style the dropdown arrow */
.options-dropdown select::-ms-expand {
    display: none;
}

.options-dropdown select:hover {
    border-color: #3498db;
}

.options-dropdown select:focus {
    border-color: #3498db;
    box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
}

label {
    display: block;
    margin-bottom: 5px;
}

.customization-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.tab-hr {
    width: 100%;
}

select,
.generation-input {
    width: 90%;
    padding: 5px;
    margin-right: 0;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.checkbox-container {
    display: flex;
    flex-direction: column;
}