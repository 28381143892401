.footer-section {
    /* background-color: #f0f0f0; */
    position: relative;
    z-index: 99;
    width: 100%;
    height: fit-content;
    padding-bottom: 20px;
    margin: 0 auto;
    text-align: center;
    background: linear-gradient(rgba(186, 182, 181, 0), rgba(186, 182, 181, 0.8))
}

.footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    column-gap: 20px;
    /* height: 100%; */
    /* Allow items to wrap to the next line */
}

hr {
    border: none;
    height: 2px;
    /* Set the hr color */
    color: #333;
    /* old IE */
    background-color: #333;
    /* Modern Browsers */
}

.footer-links {
    margin-bottom: 20px;
}

.footer-links a {
    text-decoration: none;
    color: #333;
    display: block;
    /* Display links as block elements */
    margin-bottom: 10px;
    font-size: 18px;
    /* Adjust font size as needed */
}

.footer-logo img {
    max-width: 100px;
    /* Adjust the size as needed */
}

.footer-contact {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 20px;
}

.footer-contact a {
    text-decoration: none;
    margin-top: 0;  
    font-size: 18px;
    color: rgba(0, 0, 255, 0.701);
    &:hover{
        text-decoration: underline;

    }
    /* Adjust font size as needed */

}

.footer-contact p {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 18px;
    /* Adjust font size as needed */
}

strong {
    margin-bottom: 10px;
}

.footer-social-media {
    display: flex;
    justify-content: center;
}

.footer-social-media a {
    margin-right: 15px;
}

.footer-social-media img {
    width: 30px;
}