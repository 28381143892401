.background-container {
    display: flex;
    /* margin-top: 20px; */
    padding: 0px;
    padding-bottom: 30px;

    z-index: 2;
    width: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
}

.image-section {
    z-index: 1;
    position: absolute;
    aspect-ratio: 960/540;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.custom-shape-divider-bottom-blue {
    z-index: 2;
    opacity: 1;
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    /* transform: rotate(180deg); */
}

.custom-shape-divider-bottom-blue svg {
    position: relative;
    display: block;
    width: calc(114% + 1.3px);
    height: 150px;
}

.custom-shape-divider-bottom-blue .shape-fill {
    fill: #2980b9;
}

.custom-shape-divider-bottom-grey {
    z-index: 0;
    opacity: 0.3;
    position: relative;
    /* bottom: 0;
    left: 0; */
    width: 100%;
    overflow: hidden;
    line-height: 0;
    /* transform: rotate(180deg); */
}

.custom-shape-divider-bottom-grey svg {
    position: relative;
    display: block;
    width: calc(134% + 1.3px);
    height: 250px;
}

.custom-shape-divider-bottom-grey .shape-fill {
    fill: #bab6b5;
}

.fly-in {
    position: absolute;
    left: -100%;
    /* Initial position off-screen to the left */
    animation: flyIn 1s forwards;
    /* Animation duration and fill-mode */
}

@keyframes flyIn {
    to {
        left: 0;
        /* Move the element to its final position (0% from the left) */
    }
}

.slide-up {
    opacity: 0;
    transform: translateY(100px); /* Start from slightly below */
    animation: slideUp 1s forwards;
}

@keyframes slideUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}