.input-upload-section {
    position: relative;
    z-index: 3;


    .input-sample-split {
        display: grid;
        grid-template-columns: 1fr;
    }
    .input-sample-split.samples{
        display: grid;
        gap: 10px;
        grid-template-columns: 3fr 1fr;
    }
}

.title-button-pair {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.input-source {
    grid-column: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px 0px 10px 0px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    /* margin: 10px; */
}

.writing-samples {
    /* margin-left: 10px; */
    grid-column: 2;
    width: 100%;
    padding: 20px 0px 10px 0px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.text-area {
    width: 96%;
    padding: 10px;
    border: transparent;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-right: 10px;
    resize: vertical;
    /* Allow vertical resizing */
    min-height: 10%;
    /* Set a minimum height */
}

.text-area:focus {
    outline: none;
    /* Remove default outline on focus */
    border-color: 1px solid #ccc;
    /* Set the border color on focus */
}

.generate {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 10px 0 0 0 ;
    border-radius: 5px;

    background-color: #fff;
}

.generate-button {
    flex: none;
    width: 200px;
    cursor: pointer;
}

.generate-button:disabled,
.generate-button[disabled] {
    background-color: #2980b9;
    cursor: not-allowed;
}

.upload-box {
    border: 2px dashed #3498db;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
    margin-bottom: 20px;
}

.separator {
    text-align: center;
    margin: 5px;
}

.separator span {
    padding: 0 10px;
    font-size: 1.2em;
}

.input-hr {
    width: 300px;
}