/* SampleCustomization.css */

.sample-customization-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9990;
    animation: fadeIn 0.5s ease-in-out;  
    transition: transform 0.3s ease-in-out;
}

.sample-customization {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    /* max-height: 500px;
    overflow-y: scroll; */
    transform: translate(-50%, -50%);
    z-index: 9999;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 70%;
    max-height: 90%;
    /* overflow-y: scroll; */
    animation: fadeIn 0.5s ease-in-out;

    .sample-actions {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }
    
    .sample-customization-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9998;
    }
    
    .h2 {
        font-size: 1.2em;
        margin-bottom: 10px;
    }
    
    .ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    
    .li {
        cursor: pointer;
        padding: 10px;
        border: 1px solid #eee;
        margin-bottom: 5px;
        border-radius: 3px;
    }
    
    .li.selected {
        background-color: #f0f8ff;
        /* Light blue, adjust as needed */
    }
    
    .buttons {
        margin-top: 15px;
    }
    
    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 20px;
        cursor: pointer;
    }

    .sample-list {
        max-height: 30%;
        overflow-y: auto;
        .sample-item {
            position: relative;
            background-color: #f8f8f8;
            border: 1px solid #ccc;
            border-radius: 8px;
            padding: 15px;
            width: 98%;
            margin-top: 0px;
            margin-bottom: 10px;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out;
        
            &:hover {
                background-color: #e0e0e0;
            }
        
            .document-info {
                display: flex;
                align-items: center;
                justify-content: space-between;
        
                h3 {
                    font-size: 1.2em;
                    margin: 0;
                    float: left;
                    color: black;
                }
        
                p {
                    font-size: 0.9em;
                    position: absolute;
                    right: 0;
                    margin-right: 5px;
                    float: right;
                    color: black;
                }
            }
        }
        .sample-item.selected{
            background-color: #3498db;
            &:hover {
                background-color: #3498db;
            }
        }
    }
}

@media (max-width: 1100px) {
    .sample-customization{
        display: flex;
        overflow-y: auto;
        width: 80%;
        z-index: 9990;
        /* justify-content: center;
        align-items: center; */

        .sample-list{
            display: flex;
            min-height: 150px;
            flex-direction: column;
            /* max-height: 300px;
            overflow-y: scroll; */
            .sample-item {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                position: relative;
                /* width: 100%; */

                max-width: 100%;
                /* Adjust to your preference */
                /* width: 2; */
                margin: 5px 0;
                background-color: #f8f8f8;
                border: 1px solid #ccc;
                border-radius: 8px;
                padding: 15px;
                margin-bottom: 10px;
                cursor: pointer;
                transition: background-color 0.3s ease-in-out;

                &:hover {
                    background-color: #e0e0e0;
                }

                .document-info {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    min-width: fit-content;
                    max-width: none;

                    h3 {
                        font-size: 1.2em;
                        margin: 0;
                        float: left;
                        color: black;
                    }

                    p {
                        font-size: 0.9em;
                        position: relative;
                        right: 0;
                        margin-right: 5px;
                        float: right;
                        color: black;
                    }
                }
            }
        }
    }
}

.button-container {
    display: flex;
    justify-content: center;
    column-gap: 10px;
    margin-bottom: 10px;
}

.action-button{
    margin-right: 0;
}

.action-button:disabled {
    background-color: #2470a3;
}

.new-sample-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    animation: fadeIn 0.5s ease-in-out;
    margin-bottom: 20px;
    .button-container {
        display: flex;
        align-items: center;
        width: 30%;
        margin: 0 auto;
        button{
            margin: 0;
          }
      }
  }
  
  .new-sample-section label {
    font-weight: bold;
  }

.new-sample-section textarea {
    width: 100%;
    height: 100px;
    padding: 5px 0 5px 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize:vertical;
}
.new-sample-section input[type="text"] {
  width: 40%;
  padding: 5px 0 5px 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}



/* .sample-item {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
} */


