
.document-config {
    display: flex;
    position: absolute;
    /* position: inherit; */
    z-index: 2;
    left: 0;
    top: 0;
    margin-top: 20px;
    margin-left: 74px;
    align-content: center;
    align-items: center;
    height: 36px;
    /* padding: 10px; */
    /* border-radius: 8px; */
    /* background-color: #fff; */
    /* border: 1px solid #ccc; */
    
    /* .back-button{
        position: absolute;
        top: 0;
        left: 0;
    } */
    
    input {
        margin-right: 10px;
        padding: 0px;
        padding-left: 5px;
        padding-right: 5px;
        border: 1px solid #ccc;
        border-radius: 5px;
        height: 100%;
        width: fit-content;
        font-size: 1.1em;
    }
}

@media (max-width: 1100px) {
    .document-config {
        order: 1;
        position: relative;
        margin-left: 5px;
        margin-top: 5px;
    }
}

