.tooltip {
    z-index: 998;
    display: flex;
    min-width: 100px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    color: black;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #eee;
    font-size: 0.8em;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.tooltip-visible {
    opacity: 1;
}

.info-icon-container {
    position: relative;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;

    i {
        position: inherit;
        font-size: 25px;
    }
}